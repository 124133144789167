// Parse config

let parseConfig: Parse.Config;
export async function initParseConfig(): Promise<void> {
    try {
        parseConfig = await Parse.Config.get();
    } catch (e: any) {
        if (
            e?.message &&
            typeof e.message === 'string' &&
            e.message.toLowerCase().includes('session token')
        ) {
            try {
                await Parse.User.logOut();
            } catch (e) {
                console.error('Error logging out user', e);
            }
            window.location.reload();
        }
    }
}

export const getParseConfig = (key: string): string => {
    if (!parseConfig) {
        return '';
    }
    return parseConfig.get(key);
};
