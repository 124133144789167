import { PaletteOptions, ThemeOptions } from '@mui/material';
import tinycolor from 'tinycolor2';

import defaultTheme from './default';
const lightenRate = 7.5;

const primary = '#00676d';
const primaryDark = '#004f53';
const primaryLight = '#3c7b7f';

const navBackground = '#00262a';
const navSelection = '#004f53';

const secondaryTheme: ThemeOptions = {
    ...defaultTheme,
    palette: {
        ...(defaultTheme.palette as PaletteOptions),
        primary: {
            main: primary,
            light: primaryLight,
            dark: primaryDark,
            contrastText: '#fff',
        },
        navigation: {
            background: navBackground,
            selected: navSelection,
            text: '#fff',
            hover: tinycolor(navSelection).lighten(lightenRate).toHexString(),
        },
        primaryLight: {
            main: '#C9D0E2',
        },
    },
    components: {
        ...defaultTheme.components,
        MuiInput: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
                underline: {
                    '&:before': {
                        borderBottomColor: primaryLight,
                    },
                    '&:hover:not(.Mui-focused):before': {
                        borderBottomColor: primaryLight,
                    },
                    '&:after': {
                        // focused
                        borderBottomColor: primary,
                    },
                },
            },
        },
    },
};

export default secondaryTheme;
