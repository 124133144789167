import { QueryClient } from 'react-query';

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchInterval: false,
            retry: 2,
            retryDelay: 500,
        },
        mutations: {
            retry: 3,
            retryDelay: 1000,
        },
    },
});

export default queryClient;
