import { SvgIcon } from '@mui/material';

export const PointerIconDark = ({ color }: { color?: string }): JSX.Element => {
    return (
        <SvgIcon viewBox="0 0 32 32">
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle cx="16" cy="16" r="16" fill={color || '#484848'} />
                <path
                    d="M15.0938 22.9219C13.6719 21.1445 10.5 16.9062 10.5 14.5C10.5 11.6016 12.8242 9.25 15.75 9.25C18.6484 9.25 21 11.6016 21 14.5C21 16.9062 17.8008 21.1445 16.3789 22.9219C16.0508 23.332 15.4219 23.332 15.0938 22.9219ZM15.75 16.25C16.707 16.25 17.5 15.4844 17.5 14.5C17.5 13.543 16.707 12.75 15.75 12.75C14.7656 12.75 14 13.543 14 14.5C14 15.4844 14.7656 16.25 15.75 16.25Z"
                    fill="black"
                    fillOpacity="0.8"
                />
            </svg>
        </SvgIcon>
    );
};
