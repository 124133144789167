import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import React from 'react';

const LoadingOverlay = ({ sx }: { sx?: SxProps<Theme> }): JSX.Element => (
    <Box
        sx={{
            position: 'absolute',
            inset: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 1,
            ...sx,
        }}
    >
        <CircularProgress color="primary" />
    </Box>
);

export default LoadingOverlay;
