const CACHE_KEY = '2024-08-01'; // If the env.json file ever has a changed schema, change this datestamp to cachebust

const storageKey = `RWL:ENV:${CACHE_KEY}`;
const isDevelopment = process.env.NODE_ENV === 'development';
const developmentServerUrl = isDevelopment ? 'http://localhost:1337' : '';
const envLocation = `${developmentServerUrl}/env.json?v=${CACHE_KEY}`;

export type EnvObject = {
    parseUrl: string;
    parseAppID: string;
    parseJSKey: string;
    serverBaseUrl: string;
};

export async function fetchEnv(): Promise<EnvObject> {
    console.log('Fetching env config');
    const response = await fetch(envLocation);
    return response.json();
}

export function writeEnvToLocalStorage(env: EnvObject): void {
    localStorage.setItem(storageKey, JSON.stringify(env));
}

export async function getEnv(): Promise<EnvObject> {
    const env = localStorage.getItem(storageKey);
    if (!env) {
        const env = await fetchEnv();
        writeEnvToLocalStorage(env);
        return env;
    }
    return JSON.parse(env);
}
