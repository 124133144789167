import { Box, CircularProgress } from '@mui/material';
import React from 'react';

const LoadingMoreOverlay = (): JSX.Element => {
    return (
        <Box
            sx={{
                position: 'fixed',
                inset: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 1,
                ml: {
                    sm: 0,
                    md: 18,
                },
            }}
        >
            <CircularProgress
                disableShrink
                color="primary"
                sx={{ animationDuration: '550ms' }}
            />
        </Box>
    );
};

export default LoadingMoreOverlay;
