import tinycolor from 'tinycolor2';
import { ThemeOptions, lighten } from '@mui/material';

const lightenRate = 7.5;
const darkenRate = 15;

export const primary = '#3175CC';
export const primaryDark = '#24459A';
const primaryLight = '#50A3F0';

export const secondary = '#202126';

export const warning = '#FFAA00';
const success = 'rgba(0, 214, 143, 1)';
export const error = 'rgba(211, 47, 47, 1)';
// New dark error variant
const errorDark = '#B11E1E';

const googleMain = '#4285F4';
const facebookMain = '#0866ff';
const yelpMain = '#c41200';
const webMain = '#232d49';
const jobNimbusMain = '#3B5EAB';
const housecallProMain = '#000000';
const jobberMain = '#FFB400';
const leapMain = '#73C795';

export const views = '#9DACFF';
export const engagement = '#5CBCF1';
export const contact = '#7CB857';
export const backgroundGraphColor = '#EEEEEE';
export const backgroundGraphColorLight = '#F6F6F6';
export const navMarkerColor = '#5CBBFF';
export const performanceHasMedia = '#F0890F';
export const performanceNoMedia = '#DCDCDC';
export const listMapToggleSelectedColor = lighten(primaryLight, 0.6);
export const disabledBackground = '#0000001f';
export const disabledText = '#00000042';
export const unmappedOrange = 'rgba(255, 170, 0, 0.30)';
export const needResponseBlue = 'rgba(2, 136, 209, 0.12)';

// ROI colors
export const roiGreenBackground = '#B2EDCF';

export const roiChipColor = '#006B25';
export const roiGradientBlueish = '#13E9F7';
export const roiGrey = '#E0E0E0';
export const roiGradientYellow = '#F6E122';
export const roiGradientRed = '#F34E76';
export const roiProgressActive = '#82ca9d';
export const roiDashedLine = '#909090';
// ROI Brag
export const roiBragMediaBackground = 'rgba(153, 236, 241, 0.2)';
export const roiBragMediaIcon = '#1A969D';
export const roiBragMediaIconBackground = '#99ECF1';
export const roiBragClicksBackground = 'rgba(223, 204, 255, 0.3);';
export const roiBragClicksIcon = '#7E4FCC';
export const roiBragClicksIconBackground = '#DFCCFF';
export const roiBragConversionsBackground = 'rgba(189, 227, 141, 0.2)';
export const roiBragConversionsIcon = '#5D9418';
export const roiBragConversionsIconBackground = '#BDE38D';
export const roiBragMappedBackground = 'rgba(254, 229, 164, 0.3)';
export const roiBragMappedIcon = '#FFA800';
export const roiBragMappedIconBackground = '#FEE5A4';

export const gbpHistoryCompetitive = '#13E9F7';
export const gbpHistoryLocation = '#40d5d5';
export const gbpHistoryOther = '#26C3C3';

export const borderGrey = 'rgba(0, 0, 0, 0.3)';
export const schedulerTodayBackground = 'rgba(49, 117, 204, 0.3)';
export const activeBackground = 'rgba(49, 117, 204, 0.08)';
export const schedulerDayBorder = 'rgba(0, 0, 0, 0.15)';
export const blackLight04 = 'rgba(0, 0, 0, 0.04)';
export const schedulerShadow =
    '0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)';

export const showcaseGradientPurple = 'rgba(151, 71, 255, 1)';
export const showcaseGradientBlue = 'rgba(79, 163, 255, 1)';
export const actionHoverSolid = '#F7F7F7'; // hex version of theme.palette.action.hover, no opacity

const defaultTheme: ThemeOptions = {
    borders: {
        light: '1px solid rgba(0, 0, 0, 0.15)',
    },
    zIndex: {
        mobileStepper: 100,
        speedDial: 105,
        appBar: 110,
        drawer: 120,
        modal: 130,
        snackbar: 140,
        tooltip: 150,
    },
    palette: {
        primary: {
            main: primary,
            light: primaryLight,
            dark: primaryDark,
        },
        navigation: {
            text: '#fff',
        },
        Google: {
            main: googleMain,
            dark: tinycolor(googleMain).darken(darkenRate).toHexString(),
            light: tinycolor(googleMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        Insights: {
            blue: {
                300: 'rgba(153, 236, 241, 0.3)',
                500: 'rgba(108, 195, 255, 1)',
            },
            orange: {
                300: 'rgba(254, 229, 164, 0.3)',
                500: 'rgba(255, 199, 0, 1)',
            },
            green: {
                300: 'rgba(189, 227, 141, 0.3)',
                500: 'rgba(107, 180, 82, 1)',
            },
            purple: {
                200: 'rgba(164, 141, 255, 0.2)',
                300: 'rgba(164, 141, 255, 0.3)',
                500: 'rgba(164, 141, 255, 1)',
            },
        },
        Facebook: {
            main: facebookMain,
            dark: tinycolor(facebookMain).darken(darkenRate).toHexString(),
            light: tinycolor(facebookMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        Yelp: {
            main: yelpMain,
            dark: tinycolor(yelpMain).darken(darkenRate).toHexString(),
            light: tinycolor(yelpMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        Web: {
            main: webMain,
            dark: tinycolor(webMain).darken(darkenRate).toHexString(),
            light: tinycolor(webMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        API: {
            main: webMain,
            dark: tinycolor(webMain).darken(darkenRate).toHexString(),
            light: tinycolor(webMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        Zapier: {
            main: webMain,
            dark: tinycolor(webMain).darken(darkenRate).toHexString(),
            light: tinycolor(webMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        JobNimbus: {
            main: jobNimbusMain,
            dark: tinycolor(jobNimbusMain).darken(darkenRate).toHexString(),
            light: tinycolor(jobNimbusMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        HousecallPro: {
            main: housecallProMain,
            dark: tinycolor(housecallProMain).darken(darkenRate).toHexString(),
            light: tinycolor(housecallProMain)
                .lighten(lightenRate)
                .toHexString(),
            contrastText: '#fff',
        },
        Jobber: {
            main: jobberMain,
            dark: tinycolor(jobberMain).darken(darkenRate).toHexString(),
            light: tinycolor(jobberMain).lighten(lightenRate).toHexString(),
            contrastText: '#fff',
        },
        Leap: {
            main: leapMain,
            dark: tinycolor(leapMain).darken(darkenRate).toHexString(),
            light: tinycolor(leapMain).lighten(lightenRate).toHexString(),
            contrastText: '#000',
        },
        primaryLight: {
            main: '#C9D0E2',
            light: activeBackground,
        },
        secondary: {
            main: secondary,
            light: tinycolor(secondary).lighten(lightenRate).toHexString(),
            dark: tinycolor(secondary).darken(darkenRate).toHexString(),
            contrastText: '#FFFFFF',
        },
        warning: {
            main: warning,
            light: 'rgba(255, 229, 158, 1)',
            dark: tinycolor(warning).darken(darkenRate).toHexString(),
        },
        error: {
            main: error,
            light: tinycolor(error).lighten(lightenRate).toHexString(),
            dark: errorDark,
        },
        success: {
            main: success,
            light: tinycolor(success).lighten(lightenRate).toHexString(),
            dark: '#006B25',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: '#B9B9B9',
            media: 'rgba(255,255,255,0.9)',
        },
        grey: {
            50: '#0000000a',
        },
        yellow: {
            main: '#FFB400',
            light: tinycolor('#FFB400').lighten(lightenRate).toHexString(),
            dark: tinycolor('#FFB400').darken(darkenRate).toHexString(),
            contrastText: '#FFFFFF',
        },
        showcase: {
            main: `linear-gradient(135deg, ${showcaseGradientPurple}, ${showcaseGradientBlue})`,
            light: showcaseGradientPurple,
            dark: showcaseGradientBlue,
            contrastText: '#FFFFFF',
        },
        shades: {
            main: 'rgba(49, 117, 204, 1)',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            xxl: 1856,
        },
    },
    shadows: [
        'none',
        '0px 4px 17px rgba(21, 41, 50, 0.10)',
        '0px 6px 17px rgba(21, 41, 50, 0.10)',
        '0px 8px 17px rgba(21, 41, 50, 0.10)',
        '0px 10px 17px rgba(21, 41, 50, 0.10)',
        '0px 12px 17px rgba(21, 41, 50, 0.10)',
        '0px 14px 17px rgba(21, 41, 50, 0.10)',
        '0px 14px 17px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)', // Dont use elevations over 10
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '0px 14px 27px rgba(21, 41, 50, 0.10)',
        '1px 1px 2px rgba(0, 0, 0, 0.75)', // elevation[23] used for overlay commands
        '0px 4px 8px rgba(21, 41, 50, 0.10)', // elevation[24] used for logo only
    ],
    backgrounds: {
        darkPanel:
            'radial-gradient(87.72% 87.72% at 82.44% 12.28%, #003F79 0%, #00183D 100%)',
        mediaBox:
            'linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.00) 100%)',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    background:
                        'radial-gradient(95% 182.47% at 79.38% 3.33%, #F9FAFC 0%, #EBF1FA 100%)',
                    // background: "linear-gradient(180deg, #EAF8FD 0%, #FFF7E9 100%)",
                    // background:
                    // 'linear-gradient(180deg, #EEF3FA 0%, #D0D7E1 100%)',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed',

                    // TODO: This is a "bleedthrough" lineHeight that we had on the old theme settings.
                    // We should slowly get rid of this as most text should use variants and not rely on this
                    // bleedthrough lineHeight.
                    lineHeight: 1.5,
                },
                html: {
                    fontSize: '16px',
                },
            },
        },
        MuiAppBar: {
            styleOverrides: {
                colorPrimary: {
                    // backgroundColor: primaryDark
                    background:
                        'radial-gradient(87.72% 87.72% at 82.44% 12.28%, #005579 0%, #00323D 100%)',
                },
            },
        },
        MuiBackdrop: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1A1A1A1A',
                },
            },
        },
        MuiPaper: {
            styleOverrides: {
                rounded: {
                    borderRadius: 10,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 4,
                    textTransform: 'none',
                },
            },
            variants: [
                {
                    props: { size: 'extraSmall' },
                    style: {
                        fontSize: '0.75rem',
                        padding: '4px 2px',
                        minWidth: 20,
                    },
                },
            ],
        },
        MuiRating: {
            styleOverrides: {
                sizeLarge: {
                    fontSize: '2.875rem',
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    // This is to adjust FontAwesome to MUI Icons
                    // https://mui.com/components/icons/#font-awesome-2
                    boxSizing: 'content-box',
                    padding: 3,
                    fontSize: '1.125rem',
                },
                fontSizeInherit: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiIcon: {
            styleOverrides: {
                root: {
                    // This is to adjust FontAwesome to MUI Icons
                    // https://mui.com/components/icons/#font-awesome-2
                    boxSizing: 'content-box',
                    padding: 3,
                    fontSize: '1.125rem',
                },
                fontSizeInherit: {
                    fontSize: 'inherit',
                },
            },
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    boxShadow:
                        '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    margin: 0,
                },
                underline: {
                    '&:before': {
                        borderBottomColor: primaryLight,
                    },
                    '&:hover:not(.Mui-focused):before': {
                        borderBottomColor: primaryLight,
                    },
                    '&:after': {
                        // focused
                        borderBottomColor: primary,
                    },
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                shrink: true,
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                notched: true,
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-root': {
                        '.MuiOutlinedInput-input': {
                            height: '2rem',
                            paddingTop: '.25rem',
                            paddingBottom: '.25rem',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        },
                    },
                },
            },
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '.MuiAutocomplete-inputRoot': {
                        height: '2.5rem',
                        paddingTop: '.5rem',
                        paddingBottom: '.5rem',
                        flexWrap: 'nowrap',
                    },
                    '.MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - .875rem)',

                        '.MuiButtonBase-root': {
                            height: '1.75rem',
                            width: '1.75rem',
                        },
                        '.MuiAutocomplete-clearIndicator': {
                            '.MuiSvgIcon-root': {
                                fontSize: '1.25rem',
                            },
                        },
                        '.MuiSvgIcon-root': {
                            fontSize: '1.5rem',
                        },
                    },
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                standard: {
                    '&:focus': {
                        backgroundColor: '#FFFFFF',
                    },
                },
                icon: {
                    color: 'rgb(0, 0, 0, 0.54)',
                    fontSize: 24,
                    height: '1.5rem',
                    width: '1.5rem',
                    top: 'calc(50% - 1rem)',
                },
                select: {
                    height: 'auto',
                    minHeight: '2rem',
                    lineHeight: '2rem',
                    paddingTop: '.25rem',
                    paddingBottom: '.25rem',
                },
            },
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiTouchRipple: {
            styleOverrides: {
                child: {
                    backgroundColor: 'white',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                arrow: {
                    color: 'rgba(32, 33, 38, 1)',
                },
                tooltip: {
                    backgroundColor: 'rgba(32, 33, 38, 1)',
                },
            },
        },
    },
};

export default defaultTheme;
