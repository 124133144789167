import { SvgIcon } from '@mui/material';

export const LogoOverlay = (): JSX.Element => {
    return (
        <SvgIcon viewBox="0 0 168 168">
            <svg
                width="168"
                height="168"
                viewBox="0 0 168 168"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M84 0H0V84V168H84H168V84V0H84ZM84 0C130.392 0 168 37.6081 168 84C168 130.392 130.392 168 84 168C37.6081 168 0 130.392 0 84C0 37.6081 37.6081 0 84 0Z"
                    fill="white"
                    fillOpacity="0.7"
                />
            </svg>
        </SvgIcon>
    );
};
