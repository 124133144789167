import React, { Suspense } from 'react';
import * as Parse from 'parse';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import Themes from './themes';
import { initParseConfig } from 'store/config';
import queryClient from './store/queryClient';
import { LoadingOverlay } from 'components/Icons';
import {
    ApplicationFeature,
    ApplicationFeatureService,
} from 'utils/featureService';
import { SYSTEM_PATH } from 'ui/constants';
import { getEnv } from './store/env';

export const isDevelopment = process.env.NODE_ENV === 'development';

const AppRouter = React.lazy(() => import('./AppRouter'));

getEnv()
    .then(({ parseUrl, parseAppID, parseJSKey, serverBaseUrl }) => {
        console.log('Env fetched, prepping app');

        if (!parseUrl || !parseAppID || !parseJSKey || !serverBaseUrl) {
            const config = {
                parseUrl: parseUrl ? 'SET' : 'MISSING',
                parseAppID: parseAppID ? 'SET' : 'MISSING',
                parseJSKey: parseJSKey ? 'SET' : 'MISSING',
                serverBaseUrl: serverBaseUrl ? 'SET' : 'MISSING',
            };
            throw new Error(
                `Missing one or more required configs: ${JSON.stringify(
                    config
                )}`
            );
        }

        Parse.initialize(parseAppID, parseJSKey);
        Parse.User.allowCustomUserClass(true);

        // @ts-ignore
        Parse.serverURL = parseUrl;
        Parse.enableLocalDatastore();

        console.log(`Using server ${Parse.serverURL}`);

        const path = location.pathname;
        if (path.startsWith('/_cb')) {
            // eslint-disable-next-line no-restricted-globals
            console.log('callback', location);
        }

        window.Parse = Parse;
        (window as any).theme = Themes.default;

        // enable feature service
        const featureService = new ApplicationFeatureService(
            window.localStorage,
            isDevelopment
        );
        (window as any).APPLICATION_FEATURES = ApplicationFeature;
        (window as any).features = featureService;

        // make sure we have our configs before rendering the application
        initParseConfig()
            .then(() => {
                const theme =
                    window.location.pathname.indexOf(SYSTEM_PATH) !== -1
                        ? Themes.secondary
                        : Themes.default;
                ReactDOM.render(
                    <React.StrictMode>
                        <ThemeProvider theme={theme}>
                            <QueryClientProvider client={queryClient}>
                                <Suspense fallback={<LoadingOverlay />}>
                                    <AppRouter />
                                </Suspense>
                            </QueryClientProvider>
                        </ThemeProvider>
                    </React.StrictMode>,
                    document.getElementById('root')
                );
            })
            .catch((err) => {
                console.info(err);
                Sentry.captureException(err);
            });

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register();

        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals();
    })
    .catch((err) => {
        console.error('Failed to fetch env config', err);
    });
