import { SvgIcon } from '@mui/material';

export const CheckIcon = (): JSX.Element => {
    return (
        <SvgIcon viewBox="0 0 20 20">
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="20" height="20" rx="10" fill="#3175CC" />
                <path
                    d="M15.4766 6.14844C15.832 6.47656 15.832 7.05078 15.4766 7.37891L8.47656 14.3789C8.14844 14.7344 7.57422 14.7344 7.24609 14.3789L3.74609 10.8789C3.39062 10.5508 3.39062 9.97656 3.74609 9.64844C4.07422 9.29297 4.64844 9.29297 4.97656 9.64844L7.84766 12.5195L14.2461 6.14844C14.5742 5.79297 15.1484 5.79297 15.4766 6.14844Z"
                    fill="white"
                    fillOpacity="0.8"
                />
            </svg>
        </SvgIcon>
    );
};
