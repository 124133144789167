import { SvgIcon } from '@mui/material';

export const NewImage = ({ color }: { color?: string }): JSX.Element => (
    <SvgIcon viewBox="0 0 24 22">
        <svg
            width="24"
            height="22"
            viewBox="0 0 24 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_128_20776)">
                <path
                    d="M11.297 14.4691C11.297 12.6231 12.0914 10.9657 13.3545 9.8055L12.943 9.24541C12.8516 9.07967 12.6458 8.9768 12.4286 8.9768C12.2115 8.9768 12 9.07967 11.88 9.24541L8.02791 14.452L6.69054 12.7889C6.56481 12.6345 6.37049 12.5431 6.15903 12.5431C5.94756 12.5431 5.74753 12.6345 5.62751 12.7889L2.90135 16.178V4.63894C2.90135 4.31889 3.15853 4.0617 3.47859 4.0617H17.3494C17.6695 4.0617 17.9267 4.31889 17.9267 4.63894V8.13666C18.5211 8.16523 19.0869 8.26239 19.6298 8.43956V4.63894C19.6298 3.36444 18.6239 2.32999 17.3209 2.32999H3.43858C2.19266 2.32999 1.1582 3.36444 1.1582 4.63894V16.1951C1.1582 17.4696 2.19266 18.5041 3.43858 18.5041H12.7601C11.8514 17.4068 11.3027 15.9951 11.3027 14.4577L11.297 14.4691Z"
                    fill={color ? color : '#3175CC'}
                />
                <path
                    d="M4.91312 7.24508C4.91312 8.20524 5.69039 8.9768 6.64483 8.9768C7.59927 8.9768 8.37654 8.19952 8.37654 7.24508C8.37654 6.29064 7.59927 5.51337 6.64483 5.51337C5.69039 5.51337 4.91312 6.29064 4.91312 7.24508Z"
                    fill={color ? color : '#3175CC'}
                />
                <path
                    d="M17.6409 9.26257C14.7719 9.26257 12.4401 11.5944 12.4401 14.4634C12.4401 17.3325 14.7719 19.6643 17.6409 19.6643C20.51 19.6643 22.8418 17.3325 22.8418 14.4634C22.8418 11.5944 20.51 9.26257 17.6409 9.26257ZM19.9499 15.0121H18.2182V16.7438C18.2182 17.0924 17.9553 17.321 17.6409 17.321C17.3266 17.321 17.0637 17.0924 17.0637 16.7438V15.0121H15.332C15.0119 15.0121 14.7547 14.7835 14.7547 14.4349C14.7547 14.1491 15.0176 13.8576 15.332 13.8576H17.0637V12.1259C17.0637 11.8401 17.3266 11.5487 17.6409 11.5487C17.9553 11.5487 18.2182 11.8401 18.2182 12.1259V13.8576H19.9499C20.2699 13.8576 20.5271 14.1491 20.5271 14.4349C20.5271 14.7835 20.2642 15.0121 19.9499 15.0121Z"
                    fill={color ? color : '#3175CC'}
                />
            </g>
            <defs>
                <clipPath id="clip0_128_20776">
                    <rect
                        width="21.6836"
                        height="17.34"
                        fill="white"
                        transform="translate(1.1582 2.32999)"
                    />
                </clipPath>
            </defs>
        </svg>
    </SvgIcon>
);
