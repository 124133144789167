import { SvgIcon, SxProps, Theme } from '@mui/material';

export const AllDone = ({ sx }: { sx?: SxProps<Theme> }): JSX.Element => {
    return (
        <SvgIcon width="370" height="317" viewBox="0 0 370 317" sx={{ ...sx }}>
            <svg
                width="370"
                height="317"
                viewBox="0 0 370 317"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g clipPath="url(#clip0_9516_12848)">
                    <path
                        d="M27.7837 290.908C29.9157 291.656 32.2049 291.844 34.4305 291.452C36.6562 291.061 38.7433 290.104 40.491 288.673C44.9417 284.943 46.3374 278.8 47.4725 273.111L50.8308 256.281L43.7999 261.115C38.7433 264.591 33.5731 268.179 30.0723 273.214C26.5715 278.248 25.0442 285.122 27.8562 290.57"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M28.8695 312.454C27.9845 306.017 27.074 299.497 27.6963 292.991C28.2477 287.212 30.0134 281.57 33.6081 276.941C35.5161 274.489 37.8378 272.389 40.4689 270.734C41.1548 270.301 41.7863 271.387 41.1033 271.817C36.5507 274.69 33.0294 278.931 31.0471 283.931C28.8578 289.49 28.5062 295.551 28.8833 301.457C29.1113 305.028 29.5956 308.576 30.0821 312.12C30.1205 312.28 30.0965 312.449 30.015 312.592C29.9335 312.736 29.8005 312.843 29.643 312.892C29.4823 312.935 29.311 312.913 29.1663 312.831C29.0215 312.749 28.9151 312.613 28.8699 312.454L28.8695 312.454Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M35.2393 302.126C36.1535 303.514 37.4104 304.643 38.8887 305.405C40.3671 306.167 42.0171 306.536 43.6798 306.477C47.9536 306.274 51.5152 303.297 54.7211 300.47L64.2053 292.109L57.9286 291.809C53.4146 291.594 48.7841 291.391 44.4847 292.783C40.1852 294.174 36.2201 297.517 35.4341 301.961"
                        fill="#E6E6E6"
                    />
                    <path
                        d="M26.3678 316.155C30.6283 308.628 35.5697 300.263 44.3998 297.59C46.8549 296.849 49.4291 296.583 51.9839 296.808C52.7892 296.877 52.5881 298.116 51.7845 298.047C47.5018 297.692 43.2275 298.821 39.681 301.244C36.2682 303.564 33.6108 306.788 31.362 310.207C29.9843 312.302 28.7503 314.485 27.5163 316.666C27.122 317.363 25.9689 316.86 26.3678 316.155Z"
                        fill="#F2F2F2"
                    />
                    <path
                        d="M367.27 223.24H132.384C131.66 223.239 130.966 222.952 130.454 222.441C129.943 221.93 129.655 221.237 129.654 220.515V2.72576C129.655 2.00314 129.943 1.31037 130.454 0.7994C130.966 0.288426 131.66 0.000944654 132.384 0H367.27C367.994 0.000944654 368.688 0.288426 369.199 0.7994C369.711 1.31037 369.999 2.00314 370 2.72576V220.514C369.999 221.237 369.711 221.93 369.199 222.441C368.688 222.952 367.994 223.239 367.27 223.24Z"
                        fill="white"
                    />
                    <path
                        d="M367.27 223.24H132.384C131.66 223.239 130.966 222.952 130.454 222.441C129.943 221.93 129.655 221.237 129.654 220.515V2.72576C129.655 2.00314 129.943 1.31037 130.454 0.7994C130.966 0.288426 131.66 0.000944654 132.384 0H367.27C367.994 0.000944654 368.688 0.288426 369.199 0.7994C369.711 1.31037 369.999 2.00314 370 2.72576V220.514C369.999 221.237 369.711 221.93 369.199 222.441C368.688 222.952 367.994 223.239 367.27 223.24ZM132.384 1.08816C131.95 1.0891 131.534 1.26156 131.228 1.56779C130.921 1.87403 130.748 2.2891 130.747 2.72219V220.514C130.748 220.947 130.921 221.362 131.228 221.668C131.534 221.975 131.95 222.147 132.384 222.148H367.27C367.704 222.147 368.12 221.975 368.426 221.669C368.733 221.362 368.906 220.947 368.907 220.514V2.72576C368.906 2.29268 368.733 1.87761 368.426 1.57137C368.119 1.26514 367.704 1.09267 367.27 1.09173L132.384 1.08816Z"
                        fill="#CACACA"
                    />
                    <path
                        d="M243.372 56.5928C242.881 56.5936 242.41 56.7889 242.063 57.1359C241.716 57.4828 241.521 57.9531 241.521 58.4433C241.521 58.9335 241.716 59.4037 242.063 59.7507C242.41 60.0976 242.881 60.293 243.372 60.2938H345.074C345.565 60.293 346.036 60.0976 346.383 59.7507C346.729 59.4037 346.924 58.9335 346.924 58.4433C346.924 57.9531 346.729 57.4828 346.383 57.1359C346.036 56.7889 345.565 56.5936 345.074 56.5928H243.372Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M243.372 67.6973C242.881 67.6985 242.411 67.8939 242.064 68.2409C241.718 68.5878 241.523 69.0578 241.523 69.5478C241.523 70.0378 241.718 70.5078 242.064 70.8547C242.411 71.2017 242.881 71.3971 243.372 71.3983H295.293C295.784 71.3971 296.254 71.2017 296.601 70.8547C296.947 70.5078 297.142 70.0378 297.142 69.5478C297.142 69.0578 296.947 68.5878 296.601 68.2409C296.254 67.8939 295.784 67.6985 295.293 67.6973H243.372Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M154.481 127.412C153.99 127.413 153.52 127.609 153.173 127.956C152.826 128.303 152.632 128.773 152.632 129.263C152.632 129.753 152.826 130.223 153.173 130.57C153.52 130.917 153.99 131.112 154.481 131.113H345.173C345.664 131.112 346.134 130.917 346.481 130.57C346.827 130.223 347.022 129.753 347.022 129.263C347.022 128.773 346.827 128.303 346.481 127.956C346.134 127.609 345.664 127.413 345.173 127.412H154.481Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M154.481 138.517C153.99 138.518 153.52 138.713 153.173 139.06C152.826 139.407 152.632 139.877 152.632 140.367C152.632 140.857 152.826 141.327 153.173 141.674C153.52 142.021 153.99 142.216 154.481 142.218H295.392C295.883 142.216 296.353 142.021 296.7 141.674C297.046 141.327 297.241 140.857 297.241 140.367C297.241 139.877 297.046 139.407 296.7 139.06C296.353 138.713 295.883 138.518 295.392 138.517H154.481Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M154.481 149.369C153.99 149.37 153.52 149.566 153.173 149.913C152.826 150.26 152.632 150.73 152.632 151.22C152.632 151.71 152.826 152.18 153.173 152.527C153.52 152.874 153.99 153.069 154.481 153.07H345.173C345.664 153.069 346.134 152.874 346.481 152.527C346.827 152.18 347.022 151.71 347.022 151.22C347.022 150.73 346.827 150.26 346.481 149.913C346.134 149.566 345.664 149.37 345.173 149.369H154.481Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M154.481 160.474C153.99 160.475 153.52 160.67 153.173 161.017C152.826 161.364 152.632 161.834 152.632 162.324C152.632 162.814 152.826 163.284 153.173 163.631C153.52 163.978 153.99 164.173 154.481 164.175H295.392C295.883 164.173 296.353 163.978 296.7 163.631C297.046 163.284 297.241 162.814 297.241 162.324C297.241 161.834 297.046 161.364 296.7 161.017C296.353 160.67 295.883 160.475 295.392 160.474H154.481Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M154.481 171.615C153.99 171.616 153.52 171.812 153.173 172.159C152.826 172.506 152.632 172.976 152.632 173.466C152.632 173.956 152.826 174.426 153.173 174.773C153.52 175.12 153.99 175.315 154.481 175.316H345.173C345.664 175.315 346.134 175.12 346.481 174.773C346.827 174.426 347.022 173.956 347.022 173.466C347.022 172.976 346.827 172.506 346.481 172.159C346.134 171.812 345.664 171.616 345.173 171.615H154.481Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M154.481 182.72C153.99 182.721 153.52 182.916 153.173 183.263C152.826 183.61 152.632 184.08 152.632 184.57C152.632 185.06 152.826 185.53 153.173 185.877C153.52 186.224 153.99 186.42 154.481 186.421H295.392C295.883 186.42 296.353 186.224 296.7 185.877C297.046 185.53 297.241 185.06 297.241 184.57C297.241 184.08 297.046 183.61 296.7 183.263C296.353 182.916 295.883 182.721 295.392 182.72H154.481Z"
                        fill="#E4E4E4"
                    />
                    <path
                        d="M184.684 90.5823C179.418 90.5823 174.269 89.0231 169.89 86.1017C165.511 83.1803 162.098 79.028 160.082 74.1698C158.067 69.3117 157.539 63.9659 158.567 58.8085C159.594 53.6511 162.13 48.9138 165.855 45.1955C169.579 41.4772 174.323 38.945 179.489 37.9191C184.655 36.8932 190.009 37.4197 194.875 39.432C199.74 41.4443 203.899 44.852 206.825 49.2243C209.751 53.5965 211.313 58.7368 211.313 63.9953C211.305 71.0441 208.497 77.8019 203.505 82.7862C198.513 87.7705 191.744 90.5742 184.684 90.5823Z"
                        fill="#115CED"
                    />
                    <path
                        d="M103.852 191.11C103.806 190.14 103.547 189.191 103.094 188.332C102.641 187.472 102.005 186.722 101.23 186.134C100.456 185.546 99.5611 185.135 98.61 184.93C97.6589 184.724 96.6744 184.73 95.7256 184.945L86.9237 172.561L78.0811 176.065L90.7571 193.381C91.2256 194.948 92.2538 196.289 93.6466 197.15C95.0395 198.012 96.7003 198.333 98.3144 198.053C99.9285 197.773 101.384 196.912 102.404 195.632C103.425 194.353 103.94 192.744 103.852 191.11Z"
                        fill="#A0616A"
                    />
                    <path
                        d="M86.6971 190.192L60.064 155.696L70.0432 124.382C70.7744 116.509 75.7086 114.311 75.9186 114.221L76.2388 114.084L84.9226 137.205L78.5467 154.181L94.1962 180.459L86.6971 190.192Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M179.189 83.8504C178.224 83.9713 177.297 84.302 176.473 84.8194C175.649 85.3367 174.949 86.0281 174.422 86.8448C173.895 87.6614 173.554 88.5835 173.423 89.5461C173.291 90.5087 173.373 91.4884 173.661 92.4162L161.975 102.135L166.158 110.667L182.469 96.7098C183.998 96.1222 185.258 94.9951 186.01 93.542C186.762 92.0889 186.954 90.4108 186.55 88.8257C186.146 87.2406 185.173 85.8587 183.816 84.9417C182.46 84.0247 180.813 83.6364 179.189 83.8504Z"
                        fill="#A0616A"
                    />
                    <path
                        d="M179.599 100.997L147.211 130.176L115.17 122.664C107.252 122.544 104.675 117.803 104.569 117.601L104.408 117.292L126.824 106.861L144.269 111.895L169.3 94.2852L179.599 100.997Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M123.497 308.429L132.199 308.429L136.339 274.916L123.496 274.917L123.497 308.429Z"
                        fill="#A0616A"
                    />
                    <path
                        d="M148.982 316.496L121.633 316.497L121.632 305.947L141.309 305.946C143.344 305.946 145.296 306.753 146.735 308.19C148.174 309.626 148.982 311.575 148.982 313.607L148.982 316.496Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M88.7159 308.429L97.4182 308.429L101.558 274.916L88.7148 274.917L88.7159 308.429Z"
                        fill="#A0616A"
                    />
                    <path
                        d="M114.202 316.496L86.8521 316.497L86.8516 305.947L106.528 305.946C107.536 305.946 108.534 306.144 109.465 306.529C110.395 306.914 111.241 307.478 111.954 308.19C112.666 308.901 113.232 309.746 113.617 310.675C114.003 311.605 114.201 312.601 114.202 313.607L114.202 316.496Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M85.6211 186.104L86.3308 235.713L87.0411 300.913L102.657 299.496L110.465 209.492L120.402 299.496H136.524L138.147 208.783L132.469 188.94L85.6211 186.104Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M116.186 192.767C99.1945 192.768 83.5544 185.09 83.3433 184.985L83.1679 184.897L81.742 150.732C81.3285 149.525 73.1867 125.702 71.8078 118.13C70.4107 110.458 90.6591 103.725 93.118 102.938L93.676 96.7664L116.367 94.3252L119.243 102.222L127.383 105.27C128.306 105.615 129.07 106.287 129.53 107.158C129.99 108.028 130.114 109.037 129.879 109.992L125.355 128.363L136.405 189.14L134.026 189.243C128.323 191.814 122.169 192.767 116.186 192.767Z"
                        fill="#3F3D56"
                    />
                    <path
                        d="M113.746 88.4168C121.663 84.0956 124.573 74.1845 120.245 66.2798C115.917 58.375 105.99 55.4699 98.0732 59.7911C90.156 64.1122 87.2463 74.0233 91.5743 81.9281C95.9022 89.8329 105.829 92.7379 113.746 88.4168Z"
                        fill="#A0616A"
                    />
                    <path
                        d="M92.6773 89.895C95.7909 93.2064 101.573 91.4287 101.979 86.9049C102.011 86.5537 102.008 86.2002 101.972 85.8495C101.762 83.8456 100.603 82.0264 100.881 79.9106C100.944 79.384 101.14 78.8822 101.452 78.4526C103.933 75.1354 109.757 79.9363 112.098 76.9334C113.534 75.092 111.846 72.1929 112.948 70.1349C114.403 67.4187 118.71 68.7586 121.411 67.2711C124.416 65.616 124.237 61.0122 122.258 58.2119C119.846 54.7968 115.615 52.9746 111.438 52.7119C107.261 52.4493 103.112 53.5768 99.2118 55.0943C94.7806 56.8185 90.3864 59.2013 87.6595 63.0915C84.3433 67.8225 84.0241 74.1829 85.6827 79.7148C86.6916 83.08 90.1348 87.1909 92.6773 89.895Z"
                        fill="#2F2E41"
                    />
                    <path
                        d="M207.597 316.917H0.543447C0.399316 316.917 0.26108 316.86 0.159163 316.758C0.0572472 316.657 0 316.519 0 316.375C0 316.231 0.0572472 316.093 0.159163 315.991C0.26108 315.889 0.399316 315.832 0.543447 315.832H207.597C207.741 315.832 207.879 315.889 207.981 315.991C208.083 316.093 208.14 316.231 208.14 316.375C208.14 316.519 208.083 316.657 207.981 316.758C207.879 316.86 207.741 316.917 207.597 316.917Z"
                        fill="#CACACA"
                    />
                    <path
                        d="M183.147 74.0377C182.667 74.0382 182.204 73.8568 181.853 73.5301L173.072 65.3872C172.703 65.0443 172.485 64.5691 172.467 64.0661C172.449 63.5632 172.631 63.0735 172.974 62.7047C173.317 62.3359 173.793 62.1182 174.297 62.0993C174.8 62.0805 175.291 62.2621 175.661 62.6043L183.098 69.5017L197.813 54.8103C197.989 54.6329 198.199 54.492 198.43 54.3956C198.661 54.2993 198.909 54.2495 199.159 54.249C199.41 54.2486 199.658 54.2975 199.889 54.3929C200.121 54.4884 200.331 54.6286 200.508 54.8054C200.685 54.9822 200.825 55.1921 200.921 55.4232C201.017 55.6543 201.066 55.902 201.065 56.152C201.065 56.402 201.015 56.6494 200.918 56.8801C200.822 57.1108 200.68 57.3202 200.503 57.4963L184.492 73.4816C184.316 73.6581 184.106 73.7981 183.875 73.8936C183.644 73.989 183.397 74.038 183.147 74.0377Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_9516_12848">
                        <rect width="370" height="317" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </SvgIcon>
    );
};
