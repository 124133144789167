import { SvgIcon } from '@mui/material';

export const HouseIcon = ({ color }: { color?: string }): JSX.Element => {
    return (
        <SvgIcon viewBox="0 0 48 41">
            <svg
                width="48"
                height="41"
                viewBox="0 0 48 41"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_f_356_10739)">
                    <path
                        d="M44 31.5C44 34.5376 35.0457 37 24 37C12.9543 37 4 34.5376 4 31.5C4 28.4624 12.9543 26 24 26C35.0457 26 44 28.4624 44 31.5Z"
                        fill="black"
                        fillOpacity="0.14"
                    />
                </g>
                <path
                    d="M33 8.75L38 20.25V32H10.875L10.875 20.25L16.625 8.75H33Z"
                    fill="white"
                />
                <path d="M25 19H38V32H25V19Z" fill="black" fillOpacity="0.1" />
                <path
                    d="M31.2188 3H16.625L8 20.25H10.875L16.625 8.75L23.0938 20.25H25.9688H40.5625L31.2188 3Z"
                    fill={color || '#484848'}
                />
                <path d="M14 21H21V32H14V21Z" fill={color || '#484848'} />
                <path
                    d="M30 23.8438H34V27.8438H30V23.8438Z"
                    fill={color || '#484848'}
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M16.625 3L25.9688 20.25H40.5625L31.2188 3H16.625ZM33.339 10.375H28.7015L26.3882 6H25.017L27.3303 10.375H22L22.676 11.625H27.9913L30.3046 16H31.6758L29.3625 11.625H34L33.339 10.375Z"
                    fill="white"
                    fillOpacity="0.3"
                />
                <defs>
                    <filter
                        id="filter0_f_356_10739"
                        x="0"
                        y="22"
                        width="48"
                        height="19"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="2"
                            result="effect1_foregroundBlur_356_10739"
                        />
                    </filter>
                </defs>
            </svg>
        </SvgIcon>
    );
};
